import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formattedDate } from "../FormateDate/FormateDate";
import Swal from "sweetalert2";
import axios from "axios";
import { BACKEND_URL } from "../api";
export const CertificateGenerator = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const regId = searchParams.get("registrationId");

  const [registrationId, setRegistrationId] = useState(regId);
  const [passportNumber, setPassportNumber] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [showData, setShowData] = useState(false); // Initialize loading state as false
  const [error, setError] = useState(""); // Initialize error state as empty string
  const history = useNavigate();

  // Function to fetch employee data based on search criteria
  // Function to fetch employee data based on search criteria
  const fetchData = () => {
    console.log(registrationId);
    axios
      .get(`${BACKEND_URL}/employee/search`, {
        params: { registrationId, passportNumber },
      })
      .then((response) => {
        const { data } = response;
        console.log(data);
        // Check if data is empty
        if (data.length === 0) {
          setEmployeeData(null);
          setShowData(false);
          setError("No records found"); // Set error message
        } else {
          setEmployeeData(data.employees);
          setShowData(true);
          setError(""); // Clear error message
        }
      })
      .catch((error) => {
        console.error("Error searching employees:", error);
        setEmployeeData(null);
        setShowData(false);
        setError("Failed to search for employees"); // Set error message
      });
  };

  // Function to extract URL parameters
  // const getUrlParams = () => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const regId = searchParams.get("registrationId");
  //   return { regId};
  // };

  useEffect(() => {
    // const { regId } = getUrlParams();
    console.log(regId);
    // const params = new URLSearchParams(window.location.search);
    // console.log(params);
    // const regIdFromURL = params.get('registrationId');
    // console.log(regIdFromURL)
    if (regId) {
      setRegistrationId(regId);
      fetchData(regId);
    }
  }, []); // Run once on component mount

  return (
    <div className="container-fluid">
      <div className="my-2">
        <form class="d-flex justify-content-end align-center" role="search" id="login-btn">
        <div style={{width:'20px', height:'25px', background:'black', borderRadius:'2px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i class="fa-regularr fa-house" style={{color:'white'}}></i>
          </div>
          <Link to="/login">
            <button
              style={{
                color: "black",
                textDecoration: "none",
                background: "none",
                border: "none",
              }}
            >
              Login
            </button>
          </Link>
        </form>
      </div>
      {/* navbar  */}

     <div className="sticky-top" id="stick" >
      <div className="container-fluid px-5" id="nav-container">
      <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <Link to="/dashboard" class="navbar-brand" href="#">
            <img
              src={require("../../assets/file.png")}
              className="header-logo"
              alt=""
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="burger"
          >
            <span class="navbar-toggler-icon" ></span>
          </button>
          <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0" id="nav-links">
              <li class="nav-item" style={{textDecoration:'none'}}>
                <Link  class="nav-link " style={{textDecoration:'none', color:'black'}} >
                  CONTACT
                </Link>
              </li>
              <li class="nav-item">
                <Link to='/certification' class="nav-link"  style={{textDecoration:'none', color:'black'}}>
                  CERTIFICATES
                </Link>
              </li>
            </ul>
            <form class="d-flex justify-content-end gap-2" role="search" id="social">
          {/* <Link to="/login">
            <button
              style={{
                color: "black",
                textDecoration: "none",
                background: "none",
                border: "none",
              }}
            >
              Login
            </button>
          </Link> */}
          <div style={{width:'20px', height:'25px', background:'black', borderRadius:'2px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i class="fa-regularr fa-house" style={{color:'white'}}></i>
          </div>
          <div style={{width:'20px', height:'25px', background:'black', borderRadius:'2px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i class="fa-regularr fa-house" style={{color:'white'}}></i>
          </div> <div style={{width:'20px', height:'25px', background:'black', borderRadius:'2px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i class="fa-regularr fa-house" style={{color:'white'}}></i>
          </div> <div style={{width:'20px', height:'25px', background:'black', borderRadius:'2px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <i class="fa-regularr fa-house" style={{color:'white'}}></i>
          </div>
         
          
        </form>
          </div>
        </div>
      </nav>
      </div>
     </div>

      <section className="search-form ">
        <div className="">
          <div className="row">
            <div className="col-md-12 col-lg-6 mb-4">
              <div className="inner-div">
                <div className="form-heading">
                  <h2 className="form-heading">Driver Search</h2>
                  <p className="text-muted">
                    Enter the passport number or registration ID to search for a
                    driver. You can use either field or both for a more refined
                    search.
                  </p>
                </div>
                <div className="searchForm">
                  <form>
                    <div className="form-group">
                      <label className="form-label" htmlFor="passportNumber">
                        Passport Number
                      </label>
                      <input
                        style={{ marginBottom: 5 }}
                        id="passportNumber"
                        className="custom-input"
                        type="text"
                        placeholder="Enter passport number"
                        name="passportNumber"
                        value={passportNumber}
                        onChange={(e) => setPassportNumber(e.target.value)}
                      />
                      <small className="form-text">
                        Search using the driver's passport number.
                      </small>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="registrationId">
                        Registration id
                      </label>
                      <input
                        id="registrationId"
                        type="text"
                        placeholder="Enter login name"
                        className="custom-input"
                        name="registrationId"
                        value={registrationId}
                        onChange={(e) => setRegistrationId(e.target.value)}
                      />
                      <small className="form-text">
                        Or search by the driver's registration ID.
                      </small>
                    </div>

                    <div className="form-group submit-btn">
                      <button
                        style={{ padding: 10 }}
                        type="submit"
                        value="Search"
                        className="search-btn"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default form submission behavior
                          fetchData(); // Call fetchData function
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* Display search results or error message */}
              {/* Display search results or error message */}
              {employeeData && employeeData.length > 0 ? (
    <section className="user-info">
        <div className="result-card">
            {/* Card Layout for Mobile */}
            <div className="mobile-card">
                {employeeData.map((employee, index) => (
                    <div key={index} className="card">
                      
                        <div className="card-body">
                            <h3>{employee.name}</h3>
                            <p><b>Last Login:</b> {formattedDate(employee.date)}</p>
                            <p><b>Registration Date:</b> {formattedDate(employee.date)}</p>
                            <p><b>Company:</b> {employee.companyName}</p>
                            <p><b>RTA Test Score:</b> {employee.rtaEnglishTest}</p>
                            <p><b>VTS Test Score:</b> {employee.rtaPsychometricTest}</p>
                            <p id="pass"><b>Result:</b> Pass</p>
                            <p><b>Categories:</b> {employee.category}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Table Layout for Web and Tablet */}
            <div className="web-tablet-table">
                <table id="search_table" className="table table-responsive w-full">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Last Login</th>
                            <th>Registration Date</th>
                            <th>Company</th>
                            <th>RTA Test Score</th>
                            <th>VTS Test Score</th>
                            <th>Result</th>
                            <th>Catego<br />ries</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employeeData.map((employee, index) => (
                            <tr key={index}>
                                <td>{employee.name}</td>
                                <td>{formattedDate(employee.date)}</td>
                                <td>{formattedDate(employee.date)}</td>
                                <td>{employee.companyName}</td>
                                <td>{employee.rtaEnglishTest}</td>
                                <td>{employee.rtaPsychometricTest}</td>
                                <td>Pass</td>
                                <td>{employee.category}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </section>
) : (
    <div className="result-card">
        {error ? <p>{error}</p> : <p>No records found</p>}
    </div>
)}

            </div>
            <div className="col-md-12 col-lg-6">
              <div className="col-12">
                <hr className="my-4" />
              </div>
              <h2 className="mb-3">Bulk Verifications</h2>
              <p className="bulk-verification">
                To verify driver details in bulk, please follow the steps below:
              </p>

              <ol className="discription">
                <li>
                  <strong>Download the Template</strong>: Click the "Download
                  Example File" button to obtain the CSV or Excel file format.
                </li>
                <li>
                  <strong>Fill in Driver Details</strong>: Enter details into
                  the downloaded file, ensuring accuracy and completeness.
                </li>
                <li>
                  <strong>Key Instructions for Data Entry</strong>:
                  <ol>
                    <li>
                      Information should come directly from the issued
                      certificate or be provided by the Roadslink Office.
                    </li>
                    <li>
                      Mandatory fields: Full Name, Company, Passport Number.
                    </li>
                    <li>
                      Include Include English and Psychometric Scores if
                      possible to counter incorrect self-reported scores.
                    </li>
                    <li>Double-check data for accuracy and completeness.</li>
                    <li>
                      Handle personal and sensitive information with care and
                      confidentiality.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Save and Prepare for Upload</strong>: Save your
                  changes in the original file format.
                </li>
                <li>
                  <strong>Upload the Filled File</strong>: Select the file by
                  clicking on "Choose File" and then upload it using "Submit
                  Your File".
                </li>
                <li>
                  <strong>Processing and Verification</strong>: Wait for the
                  upload and processing to complete, after which the results
                  will be displayed in a table.
                </li>
                <li>
                  <strong>Review the Verification Results</strong>: Check the
                  table for any mismatches, highlighted in red.
                </li>
                <li>
                  <strong>Please Note that</strong>: All certificates should be
                  issued after the date 2023-07-05. if the certificate issued in
                  previous date please contact Roadslink office via email{" "}
                  <a href="mailto:info@roadslink.ae">info@roadslink.ae</a> to
                  check
                </li>
              </ol>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="card">
                    <div className="card-header">
                      <h1>Bulk Verifications</h1>
                    </div>
                    <div className="card-body">
                      <form id="file-upload-form" method="post">
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="DWYCkwOB1YIUrB6MM2KiGyMb2GdPraFk5yZ91h9Me06SNzi0wyVsfyMx0epfKO8S"
                        />
                        <input
                          type="file"
                          className="m-2"
                          name="file"
                          accept=".csv, .xlsx"
                          required=""
                        />
                        {/* <button className="btn btn-primary m-2" id="uploadButton">
                          Submit Your File
                        </button> */}

                        <a
                          href="#"
                          className="btn btn-warning m-3 btn-text"
                          style={{ color: "white" }}
                        >
                          Submit Your File
                        </a>
                        <div
                          className="btn btn-warning"
                          role="status"
                          id="loadingSpinner"
                          style={{ display: "none" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </form>
                    </div>
                  </div>
                  <a href="#" className="btn btn-warning m-3 btn-text">
                    Download Example File
                  </a>
                </div>
              </div>

              <div
                className="container mt-4"
                id="verificationResultsContainer"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

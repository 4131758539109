import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios"; // Import Axios for making HTTP requests
import Datetime from "react-datetime";
import { BACKEND_URL } from "../api";
const Edit = ({ selectedEmployee, setIsEditing }) => {
  const [name, setName] = useState(selectedEmployee.name);
  const [email, setEmail] = useState(selectedEmployee.email);
  const [category, setCategory] = useState(selectedEmployee.category);
  const [companyName, setCompanyName] = useState(selectedEmployee.companyName);
  const [certificateCode, setCertificateCode] = useState(selectedEmployee.certificateCode);
  const [studentId, setStudentId] = useState(selectedEmployee.studentId);
  const [rtaPsychometricTest, setRtaPsychometricTest] = useState(selectedEmployee.rtaPsychometricTest);
  const [rtaEnglishTest, setRtaEnglishTest] = useState(selectedEmployee.rtaEnglishTest);
  const [registrationId, setRegistrationId] = useState(selectedEmployee.registrationId);
  const [passportNumber, setPassportNumber] = useState(selectedEmployee.passportNumber);
  const [selectedDate, setSelectedDate] = useState(selectedEmployee.date);

  const handleEdit = (e) => {
    e.preventDefault();
    const updatedEmployee = {
      ...selectedEmployee,
      name: name,
      email: email,
      category: category,
      companyName: companyName,
      certificateCode: certificateCode,
      studentId: studentId,
      rtaPsychometricTest: rtaPsychometricTest,
      rtaEnglishTest: rtaEnglishTest,
      registrationId: registrationId,
      passportNumber: passportNumber,
      date: selectedDate
    };

    // Make a PUT request to update the employee
    axios
      .put(`${BACKEND_URL}employee/updateEmployee/${selectedEmployee._id}`, updatedEmployee)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: `${updatedEmployee.name}'s data has been updated.`,
          showConfirmButton: false,
          timer: 1500,
        });
        setIsEditing(false); // Close the edit form after successful update
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to update employee data.",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="small-container">
      <form onSubmit={handleEdit}>
        <h1>Edit Employee</h1>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="companyName">Company Name</label>
        <input
          id="companyName"
          type="text"
          name="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />

        <label htmlFor="certificateCode">Certificate Code</label>
        <input
          id="certificateCode"
          type="text"
          name="certificateCode"
          value={certificateCode}
          onChange={(e) => setCertificateCode(e.target.value)}
        />

        <label htmlFor="studentId">Student ID</label>
        <input
          id="studentId"
          type="text"
          name="studentId"
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
        />

        <label htmlFor="rtaPsychometricTest">RTA Psychometric Test</label>
        <input
          id="rtaPsychometricTest"
          type="text"
          name="rtaPsychometricTest"
          value={rtaPsychometricTest}
          onChange={(e) => setRtaPsychometricTest(e.target.value)}
        />

        <label htmlFor="rtaEnglishTest">RTA English Test</label>
        <input
          id="rtaEnglishTest"
          type="text"
          name="rtaEnglishTest"
          value={rtaEnglishTest}
          onChange={(e) => setRtaEnglishTest(e.target.value)}
        />

      <label htmlFor="rtaEnglishTest">Category</label>
        <input
          id="rtaEnglishTest"
          type="text"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />

        <label htmlFor="registrationId">Registration ID</label>
        <input
          id="registrationId"
          type="text"
          name="registrationId"
          value={registrationId}
          onChange={(e) => setRegistrationId(e.target.value)}
        />

        <label htmlFor="passportNumber">Passport Number</label>
        <input
          id="passportNumber"
          type="text"
          name="passportNumber"
          value={passportNumber}
          onChange={(e) => setPassportNumber(e.target.value)}
        />

        <label htmlFor="date">Select Date/Time</label>
        <Datetime
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
          inputProps={{ placeholder: "Select Date and Time" }}
        />

        <div style={{ marginTop: "30px" }}>
          <input type="submit" value="Save" />
          <input
            style={{ marginLeft: "12px" }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsEditing(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;

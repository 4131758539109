import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "../Login";
import Dashboard from "../Dashboard";
import { CertificateGenerator } from "../Certificate/CertificateGenerator";
import { NotFound } from "../NotFound";
import { ProctectedRoute } from "../../ProctectedRoute";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
  const token=localStorage.getItem("is_authenticated")
  console.log(token)
    setIsAuthenticated(token);
  }, [isAuthenticated]);

  return (
    <>
      {/* {isAuthenticated ? (
        <Dashboard setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <Login setIsAuthenticated={setIsAuthenticated} />
      )} */}

      <Router>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/certification" replace={true} />}
          />
          <Route path="*" element={<Navigate to="/404" replace={true} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<NotFound />} />

          <Route
            path="/dashboard"
            element={
              <ProctectedRoute>
                <Dashboard />
               </ProctectedRoute>
            }
          />
          <Route
            path="/certification"
            element={<CertificateGenerator />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;

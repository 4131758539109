/* eslint-disable no-param-reassign */
import axios from 'axios';
// import { getToken } from 'utils/auth';

// create an axios instance
const httpService = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || "",
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 10000, // request timeout
});

// request interceptor
httpService.interceptors.request.use(
    config => {
        // do something before request is sent
        const token = JSON.parse(localStorage.getItem('userData'));
        if (token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers['x-access-token'] = getToken();
        }
        return config;
    },
    error => Promise.reject(error),
);

// response interceptor
httpService.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        // if the custom code is not 20000, it is judged as an error.
        if (![200, 201, 204].includes(response.status)) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

            // handleLogout();
            return Promise.reject(new Error(response.message || 'Error'));
        }
        return response.data;
    },
    error => Promise.reject(error),
);

// const handleLogout = () => {
//     const user = {
//         name: '',
//         email: '',
//         password: '',
//         role: null,
//     };
//     localStorage.setItem('userData', JSON.stringify(user));
//     window.location = '/';
// };

export default httpService;
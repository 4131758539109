import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, Navigate } from 'react-router-dom';
import { BACKEND_URL } from '../api';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}admin/adminLogin`, {
        email,
        password,
      });

      // Assuming the backend sends back a token upon successful login
      const token = response.data.token;

      localStorage.setItem('is_authenticated', token);
      // Redirect to dashboard upon successful login
      navigate('/dashboard');

    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response.data.message || 'An error occurred during login.',
        showConfirmButton: true,
      });
    }
  };
 
  const token = localStorage.getItem('is_authenticated');
  const isLogin = token !== null && token !== undefined;

  if (isLogin) return <Navigate to='/dashboard' />;

  return (
    <div className="small-container">
      <form onSubmit={handleLogin}>
        <h1>Admin Login</h1>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="admin@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="****"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input style={{ marginTop: '12px' }} type="submit" value="Login" />
      </form>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"; // Import CSS styles

import { postData } from "../../services/http";
import axios from "axios";
import { BACKEND_URL } from "../api";
const Add = ({ setIsAdding }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [category, setCategory] = useState("");
  const [certificateCode, setCertificateCode] = useState("");
  const [studentId, setStudentId] = useState("");
  const [rtaPsychometricTest, setRtaPsychometricTest] = useState("");
  const [rtaEnglishTest, setRtaEnglishTest] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (
      !name ||
      !category ||
      !companyName ||
      !certificateCode ||
      !studentId ||
      !rtaEnglishTest ||
      !rtaPsychometricTest ||
      !passportNumber ||
      !selectedDate
    ) {
      return Swal.fire({
        icon: "error",
        title: "Error!",
        text: "All fields are required.",
        showConfirmButton: true,
      });
    }

    const newEmployee = {
      registrationId,
      passportNumber,
      name,
      email,
      rtaEnglishTest,
      rtaPsychometricTest,
      studentId,
      certificateCode,
      companyName,
      category,
      date: new Date(selectedDate),
    };

    // Make a POST request using Axios
    axios.post(`${BACKEND_URL}employee/addEmployees`, newEmployee)
      .then((response) => {
        const { data } = response;
        setIsAdding(false);
        // Handle success
        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `${data.name} data has been Added.`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  return (
    <div className="small-container">
      <form onSubmit={handleAdd}>
        <h1>Add Student</h1>
        <label htmlFor="registrationId">Registration Id</label>
        <input
          id="registrationId"
          type="text"
          name="registrationId"
          value={registrationId}
          onChange={(e) => setRegistrationId(e.target.value)}
        />
        <label htmlFor="registrationId">Passport Number</label>
        <input
          id="passportNumber"
          type="text"
          name="passportNumber"
          value={passportNumber}
          onChange={(e) => setPassportNumber(e.target.value)}
        />

        <label htmlFor="firstName">Name</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="certificateCode">Certificate Code</label>
        <input
          id="certificateCode"
          type="text"
          name="certificateCode"
          value={certificateCode}
          onChange={(e) => setCertificateCode(e.target.value)}
        />

        <label htmlFor="studentId">Student ID</label>
        <input
          id="studentId"
          type="text"
          name="studentId"
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
        />
        <label htmlFor="rtaPsychometricTest">RTA Psychometric Test</label>
        <input
          id="rtaPsychometricTest"
          type="text"
          name="rtaPsychometricTest"
          value={rtaPsychometricTest}
          onChange={(e) => setRtaPsychometricTest(e.target.value)}
        />
        <label htmlFor="rtaEnglishTest">RTA English Test</label>
        <input
          id="rtaEnglishTest"
          type="text"
          name="rtaEnglishTest"
          value={rtaEnglishTest}
          onChange={(e) => setRtaEnglishTest(e.target.value)}
        />
        <label htmlFor="category">Category</label>
        <input
          id="category"
          type="text"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <label htmlFor="companyName">Company Name</label>
        <input
          id="companyName"
          type="text"
          name="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />

        <label htmlFor="date">Select Date/Time</label>
        {/* <input
          id="date"
          type="date"
          name="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        /> */}
        <div>
          {/* <h2>Date and Time Picker</h2> */}
          <Datetime
            id="date"
            value={selectedDate}
            onChange={handleDateChange}
            inputProps={{ placeholder: "Select Date and Time" }}
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          <input type="submit" value="Add" />
          <input
            style={{ marginLeft: "12px" }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => setIsAdding(false)}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;

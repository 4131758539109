
export const  formattedDate  = (createdOn) => {
    const date = new Date(createdOn);
   const newDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
     hour: 'numeric',
     minute: 'numeric',
     hour12: true, // Use 12-hour format with AM/PM
    });
    return newDate
  }
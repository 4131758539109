import React, { useState } from 'react';
import { formattedDate } from "../FormateDate/FormateDate";
import PDFGenerator from './PDFGenerator';
import { Certificate } from './Certificate'; // Import the Certificate component

const Table = ({ employees, handleEdit, handleDelete, handleViewCertificate  }) => {


  return (
    <div className="contain-table">
        <table className="striped-table">
          <thead>
            <tr>
              <th className="srNo">No.</th>
              <th className="tab-name">Name</th>
              <th className="tab-regNo">Registration Id</th>
              <th className="tab-passNumber">Passport Number</th>
              <th className="tab-companyName" style={{ width: "20%" }}>Company Name</th>
              <th className="tab-category">Category</th>
              <th className="tab-date" style={{ width: "15%" }}>Date</th>
              <th className="tab-actions text-center" colSpan={2}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {employees.length > 0 ? (
              employees.map((employee, i) => (
                <tr key={employee._id}>
                  <td>{i + 1}</td>
                  <td>{employee.name}</td>
                  <td>{employee.registrationId}</td>
                  <td>{employee.passportNumber}</td>
                  <td>{employee.companyName}</td>
                  <td>{employee.category}</td>
                  <td>{formattedDate(employee.date)}</td>
                  <td className="text-right">
                    <button
                      className="action-btn button muted-button"
                      onClick={() => handleViewCertificate(employee._id)}
                    >
                      View
                    </button>
                    <button
                      onClick={() => handleEdit(employee._id)}
                      className=" action-btn button muted-button"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(employee._id)}
                      className=" action-btn button muted-button"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No Employees</td>
              </tr>
            )}
          </tbody>
        </table>
    
    </div>
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Header from "./Header";
import Table from "./Table";
import Add from "./Add";
import Edit from "./Edit"; // Import Edit component
import { getData, remove } from "../../services/http";
import { ProctectedRoute } from "../../ProctectedRoute";
import axios from "axios"; // Import Axios for making HTTP requests
import { Certificate } from "./Certificate";
import { BACKEND_URL } from "../api";
const Dashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`${BACKEND_URL}employee/getAllEmployees`)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleEdit = (id) => {
    const [employee] = employees.filter((employee) => employee._id === id);
    setSelectedEmployee(employee);
    setIsEditing(true);
  };

  const handleViewCertificate = (id) => { // Renamed handleViewDetails to handleViewCertificate
    const [employee] = employees.filter((employee) => employee._id === id);
    setSelectedEmployee(employee);
    setIsCertificate(true);
  };

  const handleUpdate = (updatedEmployee) => {
    axios
      .put(`${BACKEND_URL}employee/updateEmployee/${updatedEmployee._id}`, updatedEmployee)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: `${updatedEmployee.name}'s data has been updated.`,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchData(); // Fetch updated data after successful update
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to update employee data.",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${BACKEND_URL}employee/deleteEmployee/${id}`)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: `Employee data has been deleted.`,
              showConfirmButton: false,
              timer: 1500,
            });
            fetchData(); // Fetch updated data after successful delete
          })
          .catch((error) => {
            console.error("Error deleting data:", error);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete employee data.",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  return (
    <div className="container">
      {!isAdding && !isEditing && !isCertificate && (
        <>
          <Header setIsAdding={setIsAdding} />
          <Table
            employees={employees}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleViewCertificate={handleViewCertificate} // Changed handleViewDetails to handleViewCertificate
          />
        </>
      )}
      {isAdding && (
        <ProctectedRoute>
          <Add
            employees={employees}
            setEmployees={setEmployees}
            setIsAdding={setIsAdding}
          />
        </ProctectedRoute>
      )}
      {isEditing && (
        <Edit
          selectedEmployee={selectedEmployee}
          setIsEditing={setIsEditing}
        />
      )}
      {isCertificate && (
        <Certificate
          selectedEmployee={selectedEmployee}
          setIsCertificate={setIsCertificate}
        />
      )}
    </div>
  );
};

export default Dashboard;
